
import { gql, useLazyQuery } from '@apollo/client';
import { useAuth } from '@/context/AuthContext';
import { useState } from 'react';

// Définition de la requête GraphQL
const GET_USER = gql`
  query GetUser($uuid: ID!) {
    user(uuid: $uuid) {
        email
        personalEmail
        phoneNumber
        addressLine1
        addressLine2
        city
        trigram
        countryCode
        entryDate
        iban
        bicCode
        cooptedBy {
            cooptant {
            firstname
            lastname
            uuid
            }
        }
        cooptantFor {
            coopted {
            firstname
            lastname
            uuid
            }
            monthsDuration
            percentage
            startDate
        }
        latestStatementMonth {
            commission
        }
        consultantProfile {
            name
            uuid
            cooptationType
        }
        retributionModel {
            upToFiveConsultants
            upToTenConsultants
            moreThanTenConsultants
            uuid
        }
        company {
            uuid
            name
        }
        zipCode
        lastname
        firstname
        uuid
    }
  }
`;

const useUser = () => {
    const { user: authUser } = useAuth();
    const [user, setUser] = useState(null);
    const [getUserQuery, { loading, error, data, refetch }] = useLazyQuery(GET_USER, {
        context: {
            headers: {
                Authorization: `Bearer ${authUser.strongToken}`,
            },
        },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data?.user) {
                setUser(data.user);
            }
        },
        onError: (error) => {
            console.error("Error fetching user:", error);
        },
    });

    // Fonction pour récupérer l'utilisateur
    const getUser = (uuid) => {
        if (!authUser.strongToken) {
            console.error('No strong token available');
            return;
        }

        getUserQuery({
            variables: { uuid },
        });
    };

    return {
        getUser,
        user: user || data?.user,
        loading,
        error,
        refetch
    };
};

export default useUser;
