import { useAuth } from '@/context/AuthContext';
import { useState } from 'react';

function usePdfRequest() {
    const [pdfFile, setPdfFile] = useState(null);
    const [error, setError] = useState(null);
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [fileName, setFileName] = useState(null);

    const translateTemplateToApiFormat = (template, languageForTemplate, anonymous) => {
        console.log("sendPdfRequest, template = ", template, languageForTemplate);

        return {
            personUuid: template.personUuid,
            language: languageForTemplate.toUpperCase(),  // Peut-être dynamique selon ton usage
            titleUuid: template.titles[0]?.uuid || null,  // Exemple d'accès au premier titre
            descriptionUuid: template.descriptions[0]?.uuid || null,
            experienceUuids: template.experiences.map(exp => exp.uuid),
            educationUuids: template.educations.map(edu => edu.uuid),
            certificationUuids: template.certifications.map(cert => cert.uuid),
            projectUuids: template.projects.map(project => project.uuid),
            mappedSkillUuids: template.mappedSkills.map(skill => skill.uuid),
            anonymous
        };
    };

    const requestPDF = async (template, languageForTemplate, anonymous) => {
        const apiUrl = `${window.ENV.BACKEND_URL}/api/secured/admin/prospect/export/pdf`;
        const translatedTemplate = template && translateTemplateToApiFormat(template, languageForTemplate, anonymous);
        console.log("sendPdfRequest, Template traduit à envoyer :", translatedTemplate);

        setLoading(true);
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user?.strongToken}`,
                },
                body: JSON.stringify(translatedTemplate),
            });
            const contentDisposition = response.headers.get('Content-Disposition');
            console.log("Content-Disposition", contentDisposition);
            console.log("response", response);
            if (!response.ok) {
                const dataError = await response.json();
                throw new Error(`HTTP error! Status: ${response.status}. Details: ${dataError?.detail}. Title: ${dataError?.title}`);
            }
            // Récupère le fichier PDF en tant que blob
            const blob = await response.blob();
            console.log("blob: ", blob);

            // Récupère le nom de fichier du PDF
            let fileName = 'unknown.pdf';
            if (contentDisposition) {
                const match = contentDisposition.match(/filename=(.+)/);
                console.log("match", match, contentDisposition);
                if (match?.length > 1) {
                    fileName = match[1];
                }
            }
            setFileName(fileName);

            const pdfUrl = URL.createObjectURL(blob); // Crée une URL temporaire pour le PDF
            setPdfFile(pdfUrl); // Stocke l'URL temporaire du PDF dans pdfFile

        } catch (err) {
            console.error("sendPdfRequest, Erreur lors de la requête :", err);
            setError(err);
        } finally {
            setLoading(false);
            console.log("sendPdfRequest, Requête terminée");
        }
    };

    return { requestPDF, pdfFile, loading, error, fileName };
}

export default usePdfRequest;
