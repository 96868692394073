import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
// Assurez-vous que le chemin d'importation est correct
import { useAuth } from "@/context/AuthContext";

export const GET_ALL_SKILLS = gql`
  query GetAllSkills($skillsRequest: SkillsRequestDto!) {
    skills(skillsRequest: $skillsRequest) {
        skills {
        companyUuid
        escoUri
        labelDe
        labelEn
        labelEs
        labelFr
        labelIt
        labelNl
        skillAssignedUsers {
            email
            firstname
            gender
            language
            lastname
            trigram
            uuid
        }
        skillAssignedUsersCount
        skillType
        uuid
        }
    hasNext
    }
  }
`;
export const useAllSkills = (searchString, sliceNumber=1, sortOrder) => {
    const { user } = useAuth();

    const { loading, error, data, refetch } = useQuery(GET_ALL_SKILLS, {
        variables: { skillsRequest: { companyUuid: user.company.uuid, searchString, sliceNumber, sortOrder } },
        context: {
            headers: {
                Authorization: `Bearer ${user.strongToken}`,
            },
        },
        fetchPolicy: "network-only", // S'assure que les données sont toujours récupérées depuis le serveur
    });

    return { loading, error, data, refetch };
};