

import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
//import { getSkillsType } from '@/services/admin-services/setSkillPanelData';
import UniqueSelect2 from "@/components/molecules/inputs/UniqueSelect2";
import { Button } from "@/components/molecules/buttons/button";
import { Label } from "@/components/atoms/texts/label";
import { getSkillsType } from "@/services/global-services/api-rest/useAITranslate";
import { useDeleteSkill } from "@/services/admin-services/mutations/useDeleteSkill";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useUpdateSkill } from "@/services/admin-services/mutations/useUpdateSkill";
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import { useGetSkillLabel } from "@/utils/getSkillLabel";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import PrintErrorMessage from "@/components/organisms/feedback/PrintErrorMessage";
export default function PrintSkillAdded({
    skill,
    setIsdeleting,
    skills,
    setChangeContent,
    setNumberOfConsultant,
    setSkillName,
    labelLangSkill,
    setSlectedSkill,
}) {
    const [skillPrinted, setSkillPrinted] = useState({
        uuid: skill.uuid,
        labelDe: skill.labelDe,
        labelEn: skill.labelEn,
        labelEs: skill.labelEs,
        labelFr: skill.labelFr,
        labelIt: skill.labelIt,
        labelNl: skill.labelNl,
        skillType: skill.skillType,
        skillAssignedUsersCount: skill.skillAssignedUsersCount,
        skillAssignedUsers: skill.skillAssignedUsers,
    });
    const { deleteSkill, loading, error } = useDeleteSkill();
    const [showButtonDelete, setShowButton] = useState(false);
    const {
        updateSkill,
        loading: loadingUpdate,
        error: errorUpdate,
    } = useUpdateSkill();
    let labelLanguage = useGetSkillLabel();

    // const handleDelete = (itemToDelete) => {
    //   //setShowForm(false);
    //   if (skill) {
    //     setSkills((prevData) =>
    //       prevData.filter((item) => item.uuid !== itemToDelete.uuid)
    //     );
    //   }
    // };
    const handleDeleteSkill = async () => {
        setShowButton(false);
        try {
            await deleteSkill(skill.uuid);
            console.log("delete skill", skill);
            //alert("Skill deleted successfully");
            setShowButton(false);
            setIsdeleting(true);
        } catch (err) {
            alert(`Failed to delete skill: ${err.message}`);
        }
    };

    const handleChange = () => {
        setChangeContent(true);
        setNumberOfConsultant(skillPrinted.skillAssignedUsersCount);
        setSkillName(skillPrinted.labelLangSkill);
        setSlectedSkill(skillPrinted);
    };
    const handleUpdate = async (skillT) => {
        const newSkill = {
            uuid: skillPrinted.uuid,
            labelDe: skillPrinted.labelDe,
            labelEn: skillPrinted.labelEn,
            labelEs: skillPrinted.labelEs,
            labelFr: skillPrinted.labelFr,
            labelIt: skillPrinted.labelIt,
            labelNl: skillPrinted.labelNl,
            skillType: skillT,
        };
        const name = "skillType";
        setSkillPrinted((prevSkillPrinted) => ({
            ...prevSkillPrinted,
            [name]: skillT,
        }));

        console.log("updated skill", newSkill);
        const updatedSkill = await updateSkill(newSkill);
        // try {
            
        //     //alert("Skill updated successfully", updatedSkill);
        // } catch (err) {
        //     alert(`Failed to update skill: ${err.message}`);
        // }
    };

    return (
        <div className="w-full ">
            <div className="w-full px-firstDegree flex flex-row gap-firstDegree items-center  justify-between ">

                <Button showLeftIcon leftIcon={<Person2RoundedIcon />} style="ghost" sizePadding="small" onClick={() => handleChange(skillPrinted)} className="rounded-sm" disabled={skillPrinted?.skillAssignedUsersCount < 1}>
                    {skillPrinted?.skillAssignedUsersCount}
                </Button>
                <div className="flex w-full">
                    <Label >
                        {skillPrinted && skillPrinted[labelLanguage]}
                    </Label>
                </div>


                <Select value={skillPrinted?.skillType} onValueChange={(value) => handleUpdate(value)}>
                    <SelectTrigger className="flex-none w-fit rounded-sm border-0 bg-white bg-lighter  gap-2 text-gray-400">
                        <SelectValue placeholder="Select" />
                    </SelectTrigger>
                    <SelectContent>
                        {getSkillsType().map((item, index) => (
                            <SelectItem key={index} value={item}><Label className="text-night">{item}</Label></SelectItem>
                        ))
                        }

                    </SelectContent>
                </Select>
                <Button
                    showLeftIcon={true}
                    leftIcon={showButtonDelete ? (<ExpandLessIcon />) : (<DeleteIcon />)}
                    sizePadding="small"
                    style="ghost"
                    showText={false}
                    onClick={() => setShowButton(!showButtonDelete)}
                    className="rounded-sm"
                />

            </div>
            {/* PRINTED when user want to delete*/}
            {showButtonDelete && (
                <div className={`w-full flex flex-row justify-between     mt-1 px-secondDegree py-firstDegree shadow-md items-center  border rounded-sm ${skillPrinted.skillAssignedUsersCount > 0 ? `border-error bg-error20` : `border-warning bg-warning20`}`}>
                    <div className=" ">

                        <Label message={
                            skillPrinted.skillAssignedUsersCount > 0
                                ? "consultants.statement.setSkillsPanel.canNotDelete"
                                : "global.actions.askConfirmDelete"
                        }
                        />
                        {skillPrinted.skillAssignedUsersCount >= 0 && (
                            <span>
                                <Label className={`${skillPrinted.skillAssignedUsersCount > 0 ? "cursor-pointer" : "cursor-not-allowed"} font-bold  text-body underline`}
                                    onClick={() => handleChange(skillPrinted)} >
                                    {skillPrinted && skillPrinted.skillAssignedUsersCount}
                                </Label>
                                {' '}
                                <Label
                                    message={skillPrinted.skillAssignedUsersCount > 1 ? "consultants.informations.consulantLowerCase" : "global.information.consultant"}
                                    size="body"
                                />
                            </span>
                        )}
                        {skillPrinted.skillAssignedUsersCount > 0 && "."}
                    </div>

                    {skillPrinted.skillAssignedUsersCount < 1 && <Button
                        showLeftIcon={true}
                        leftIcon={skillPrinted.skillAssignedUsersCount == 0 && (<DeleteIcon className="text-primary" />)}
                        padding="small"
                        style="ghost"
                        showText={false}
                        onClick={handleDeleteSkill}
                    />}
                </div>
            )}
            {(loadingUpdate || loading) && <span className="loader"/>}
            {errorUpdate && <PrintErrorMessage error={errorUpdate} />}
        </div>
    );
}
