import { useState, useEffect } from "react";
import BlueHeader from "@/components/organisms/blueHeader";
import AddProspectsPanel from "@/components/macro-organisms/panels/panels-admin/AddProspects/AddProspectsPanel";
import TableProspects from "./TableProspects";
import { useAllProspects } from "@/services/admin-services/query/useAllProspects";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import InViewComponent from "@/components/organisms/InViewComponent";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/atoms/texts/label";
import { motion } from "framer-motion";
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { useTranslation } from "react-i18next";
import { Select, SelectItem, SelectTrigger, SelectValue, SelectContent } from "@/components/ui/select";
import PrintInformationMessage from "@/components/organisms/feedback/PrintInformationMessage";

export default function ProspectsHome() {
    const [isDoingAction, setIsDoingAction] = useState(false);
    const [sliceNumber, setSliceNumber] = useState(1);
    const [lastSlice, setLastSlice] = useState(1);
    const [prospectsCopy, setProspectsCopy] = useState([]);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [shouldPrintNumberOfProspects, setShouldPrintNumberProspects] = useState(false)
    const SortOrderEnum = {
        LASTNAME_ASC: "Nom A->Z",
        LASTNAME_DESC: "Nom Z->A",
        CREATED_ASC: "Les plus récents",
        CREATED_DESC: "Les plus anciens"
    };
    const [searchString, setSearchString] = useState("");
    const [sortOrder, setSortOrder] = useState("CREATED_ASC"); //LASTNAME_ASC, LASTNAME_DESC, CREATED_ASC, CREATED_DESC

    const { loading, error, data, loadMoreProspects, refetch } = useAllProspects(lastSlice, searchString.length > 1 ? searchString : undefined, sortOrder);
    const [t] = useTranslation("global");

    useEffect(() => {
        refetch();
    }, [isDoingAction]);

    const refetchFromScratch = (n = 1) => {
        setLastSlice(n);
        setProspectsCopy([]);
        refetch(n);
        console.log("refetch from Scratch")
    };

    const fetchNewSlice = () => {
        if (!loading && prospectsCopy.length > 0 && data?.allProspects?.hasNext) {
            setLastSlice((prevSlice) => prevSlice + 1);
        }
    };

    useEffect(() => {
        if (data) {
            if (sliceNumber > 1)
                setProspectsCopy((prevProspects) => [
                    ...prevProspects,
                    ...(Array.isArray(data?.allProspects.prospects) ? data.allProspects.prospects : [])
                ]);
            else
                setProspectsCopy(data?.allProspects.prospects)
        }
    }, [loading, data]);

    useEffect(() => {
        refetch();

    }, [lastSlice, refetch, searchString.length > 2 ? searchString : ""]);

    useEffect(() => {
        refetchFromScratch();
    }, []);

    return (
        <div>
            <BlueHeader title="prospects.prospectsConsultants" />
            <div className="px-[10vw] py-thirdDegree gap-thirdDegree flex flex-col w-full">
                <div className="flex gap-secondDegree items-center">

                    <div className=" w-full flex justify-end gap-secondDegree">
                        <Select value={sortOrder} onValueChange={(value) => setSortOrder(value)}>
                            <SelectTrigger className="flex-none w-fit h-12 py-paddingTopAndBottom bg-white gap-2 text-gray-400">
                                <SelectValue placeholder={t("global.actions.select")} />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="LASTNAME_ASC"><Label className="text-night">{SortOrderEnum.LASTNAME_ASC}</Label></SelectItem>
                                <SelectItem value="LASTNAME_DESC"><Label className="text-night">{SortOrderEnum.LASTNAME_DESC}</Label></SelectItem>
                                <SelectItem value="CREATED_ASC"><Label className="text-night">{SortOrderEnum.CREATED_ASC}</Label></SelectItem>
                                <SelectItem value="CREATED_DESC"><Label className="text-night">{SortOrderEnum.CREATED_DESC}</Label></SelectItem>
                            </SelectContent>
                        </Select>

                        {/* {searchString} */}
                        <motion.div
                            className="relative "
                            animate={{ width: isInputFocused ? '100%' : 'auto' }}
                            transition={{ duration: 0.5 }} // Durée de la transition
                            onFocus={() => setIsInputFocused(true)}
                            onBlur={() => setIsInputFocused(false)}
                        >
                            {searchString?.length > 0 && <ClearRoundedIcon onMouseDown={() => { setSearchString(""); console.log("click on delete") }} className={`absolute inset-y-0 my-auto left-0 hover:cursor-pointer mx-2  ${isInputFocused ? 'text-primary' : 'text-gray-400'} `} />}
                            <Input
                                type="string"
                                placeholder={t("global.information.firstnameOrLastname")}
                                className={`${isInputFocused ? 'w-full ' : 'w-min'} h-12 py-paddingTopAndBottom ${searchString.length > 0 ? "pl-8" : ""}`}
                                onChange={(e) => setSearchString(e?.target?.value)}
                                value={searchString}
                            />
                            <div className="absolute inset-y-0 right-0 my-auto mr-secondDegree flex gap-secondDegree items-center">
                                {(searchString.length <= 1 && searchString.length > 0) && <Label color={`error`}>Min 2 car</Label>}
                                <SearchRoundedIcon
                                    onClick={() => refetch()}
                                    className={`hover:cursor-pointer ${isInputFocused ? 'text-primary' : 'text-gray-400'}`}
                                />
                            </div>
                        </motion.div>
                    </div>
                    <AddProspectsPanel
                        isDoingAction={isDoingAction}
                        setIsDoingAction={setIsDoingAction}
                        refetch={refetch}
                        setShouldPrintNumberProspects={setShouldPrintNumberProspects}
                        className=""
                    />
                </div>
                
                {(prospectsCopy?.length == 0 && searchString?.length > 0) ? <PrintInformationMessage information={"prospects.noProspectsResults"} a/> :

                    <TableProspects
                        isDoingAction={isDoingAction}
                        setIsDoingAction={setIsDoingAction}
                        loading={loading}
                        error={error}
                        data={prospectsCopy}
                        loadMoreProspects={loadMoreProspects}
                        refetch={refetch}
                        shouldPrintNumberOfProspects={shouldPrintNumberOfProspects}
                        setShouldPrintNumberProspects={setShouldPrintNumberProspects}
                    />}
                <InViewComponent inView={fetchNewSlice} />
            </div>
        </div>
    );
}
