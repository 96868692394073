import { Label } from "@/components/atoms/texts/label";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/molecules/popover";
import { Button } from "@/components/molecules/buttons/button";
import { useEffect, useState, useRef } from "react";
import Polygon1 from "@/assets/Polygon1.svg"
import ControlsDownload from "@/components/molecules/controlsDownload";
import { useTranslation } from "react-i18next";
import ReadingStatus from "./readingStatus";
import { useGetCompanyBenefitInvoice } from "@/services/consultant-services/api-rest/useGetCompanyBenefitInvoice";
import { useDeleteCompanyBenefitInvoice } from "@/services/admin-services/mutations/useDeleteCompanyBenefitInvoice";
import { useToast } from "@/components/organisms/feedback/use-toast";
import { useGetConsultantCompanyBenefitInvoice } from "@/services/admin-services/api-rest/useGetConsultantCompanyBenefitInvoice";
import { BENEFITS_TYPE } from "@/utils/globalConst";
import FormNewCompanyBenefit from "@/components/macro-organisms/panels/panels-admin/newCompanyBenefit/FormNewCompanyBenefit";
// import { useStoreCompanyBenefit } from '@/services/admin-services/api-rest/useStoreCompanyBenefit';
import { useUpdateCompanyBenefitInvoice } from "@/services/admin-services/mutations/useUpdateCompanyBenefitInvoice";
import { useStoreCompanyBenefitInvoice } from "@/services/admin-services/api-rest/useStoreCompanyBenefitInvoice";
import { useAuth } from "@/context/AuthContext";
import EditAdminModal from "@/components/macro-organisms/EditAdminModal";

// "amountTax": { "5": "7.36", "10": "4.53", "12": "7.68" },
export default function CompanyBenefits({ data, dataOwner, refetchFromScratch =()=>{}, editable = false}) {

    const [open, setOpen] = useState();
    const totalTax = (data?.totalAmount && data?.amountExcludingVat) && data?.totalAmount - data?.amountExcludingVat;
    const [t] = useTranslation("global");
    // Convertir la date passée en objet Date
    const providedDate = new Date(data?.invoiceDate);

    // Obtenir la date actuelle
    const currentDate = new Date();

    // Calculer la différence en jours
    const differenceInTime = currentDate.getTime() - providedDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    // console.log("data", data);

    const { getCompanyBenefitInvoice, loading: loadingFile, error: errorFile, data: companyBenefitFile } = dataOwner ? useGetConsultantCompanyBenefitInvoice(): useGetCompanyBenefitInvoice();
    const { deleteCompanyBenefitInvoice, loading: loadingDelete, error: errorDelete, data: dataDelete } = useDeleteCompanyBenefitInvoice();
    const { toast } = useToast();
    const { updateCompanyBenefitInvoice, loading: loadingUpdate, error: errorUpdate, data: dataUpdate } = useUpdateCompanyBenefitInvoice();
    const { storeCompanyBenefit, loading: storeLoading, error: storeError, data: storeData } = useStoreCompanyBenefitInvoice();
    const [submissionError, setSubmissionError] = useState(false);
    const [submissionSuccess, setSubmissionSuccess] = useState(false);
    const [dataToSend, setDataToSend] = useState(null);
    const submitRef = useRef(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const { hasPrivileges } = useAuth();

    const handleGetInvoice = () => {
        // console.log("handleGetInvoice", data?.companyBenefitInvoiceFileUuid)
        if (data?.companyBenefitInvoiceFileUuid) {
            getCompanyBenefitInvoice(data?.companyBenefitInvoiceFileUuid);
        }
    };

    const handleOpenInvoice = () => {
        if (!companyBenefitFile) {
            handleGetInvoice();
        }
        companyBenefitFile && window.open(companyBenefitFile?.url, "_blank")// Ouvrir l'URL dans un nouvel onglet
    }

    useEffect(() => {
        companyBenefitFile && window.open(companyBenefitFile?.url, "_blank")// Ouvrir l'URL dans un nouvel onglet
    }, [companyBenefitFile]);

    const deleteCompanyBenefit = (uuidCompanyBenefit) => {
        // console.log("deleteCompanyBenefit", uuidCompanyBenefit);
        deleteCompanyBenefitInvoice(uuidCompanyBenefit);
    }
    useEffect(() => {
        // console.log("dataDelete", dataDelete)
        if (dataDelete) {
            toast({
                variant: "success",
                title: "success.backendOperation.delete",
                duration: 8000,
                // success.sendForm
                description: t("success.sendForm.companyBenefits") + data?.month + "/" + data?.year + " " + data?.comment + t("success.sendForm.deleted")
            })
            refetchFromScratch(1)
        } else if (dataDelete?.deleteCompanyBenefitInvoice == false && errorDelete) {
            toast({
                variant: "error",
                title: "error.requestFailed",
                duration: 8000,
                description: errorDelete?.message
            })
        }
    }, [dataDelete])

    const handleSubmitUpdate = async (formData) => {
        setDataToSend(formData);
        console.log("handleSubmitUpdate formData =", formData);

        try {
            setSubmissionError(null);
            setSubmissionSuccess(false);
            if (formData.file) {
                const fileResponse = await formData.file && storeCompanyBenefit(formData.file, formData?.consultantUuid);
            } else {
                sendUpdateCompanyBenefit(null, formData);
            }
        } catch (err) {
            setSubmissionError(err.message);
            console.error("Erreur lors de la soumission:", err);
        }
    };

    const sendUpdateCompanyBenefit = async (uuidFile, formData) => {
        const updatedCompanyBenefitInvoice = uuidFile ? {
            companyBenefitInvoiceFileUuid: uuidFile || formData?.companyBenefitInvoiceFileUuid || dataToSend?.companyBenefitInvoiceFileUuid,
            period: formData ? formData?.period : dataToSend?.period,
            currency: formData ? formData?.currency : dataToSend?.currency,
            amountExcludingVat: parseFloat(formData ? formData?.amountExcludingVat : dataToSend?.amountExcludingVat),
            totalAmount: parseFloat(formData ? formData?.totalAmount : dataToSend?.totalAmount),
            comment: formData ? formData?.comment : dataToSend?.comment,
            vatList: formData ? formData?.vatList : dataToSend?.vatList,
            benefitType: formData ? formData?.benefitType : dataToSend?.benefitType,
            uuid: formData ? formData?.uuid : dataToSend?.uuid,
            invoiceDate :  formData?.invoiceDate 
        } : {
            period: formData ? formData?.period : dataToSend?.period,
            currency: formData ? formData?.currency : dataToSend?.currency,
            amountExcludingVat: parseFloat(formData ? formData?.amountExcludingVat : dataToSend?.amountExcludingVat),
            totalAmount: parseFloat(formData ? formData?.totalAmount : dataToSend?.totalAmount),
            comment: formData ? formData?.comment : dataToSend?.comment,
            vatList: formData ? formData?.vatList : dataToSend?.vatList,
            benefitType: formData ? formData?.benefitType : dataToSend?.benefitType,
            uuid: formData ? formData?.uuid : dataToSend?.uuid,
            invoiceDate :  formData?.invoiceDate 
        };
        console.log("sendUpdateCompanyBenefit uuidFile =", uuidFile, formData, updatedCompanyBenefitInvoice);

        await updateCompanyBenefitInvoice({
            variables: { updatedCompanyBenefitInvoice },
        });
    };

    useEffect(() => {
        if (storeData) {
            dataToSend && sendUpdateCompanyBenefit(storeData.uuid);
        }

        storeError && setSubmissionError(storeError);
    }, [storeError, storeData]);

    useEffect(() => {
        if (dataUpdate?.updateCompanyBenefitInvoice?.uuid) {
            setSubmissionSuccess(true);
            refetchFromScratch();
        }
        errorUpdate && setSubmissionError(errorUpdate);
    }, [errorUpdate, dataUpdate]);

    useEffect(() => {
        if (dataUpdate) {
            console.log(dataUpdate, "dataUpdate");
            toast({
                variant: "success",
                title: "success.backendOperation.update",
                duration: 5000,
                description: "L'avantage société " + dataUpdate?.updateCompanyBenefitInvoice?.month + "/" + dataUpdate?.updateCompanyBenefitInvoice?.year + " a été mise à jour"
            });
            refetchFromScratch(1);
        } else if (dataUpdate?.updatePayslip == false && errorUpdate) {
            toast({
                variant: "error",
                title: "error.requestFailed",
                duration: 8000,
                description: errorUpdate?.message
            });
        }
    }, [dataUpdate]);

    return (
        <div className="bg-white w-full shadow-md px-paddingLeftAndRight py-paddingTopAndBottom rounded-sm justify-start items-center inline-flex gap-secondDegree">
            <Label fontWeight="semibold">{data?.fact}</Label>

            <ReadingStatus isNew={differenceInDays < 30} />
            {/* <Label fontWeight={differenceInDays < 30 && "bold"}>{t("consultants.dashboard.tabs.companyBenefits") + " " + data?.month + "/" + data?.year}</Label> */}
            {/* <Label >{data?.comment}</Label>

            <div className="grow"></div>
            <Label >{data?.invoiceDate}</Label> */}



            <div className=" overflow-hidden flex gap-firstDegree flex-col md:flex-row grow items-center ">
                <Label className="text-nowrap " fontWeight={differenceInDays < 30 && "bold"}>{t(`consultants.companyBenefits.` + (BENEFITS_TYPE.find(benefit => benefit.enum === data?.benefitType)?.label || '')) + " " + data?.month + "/" + data?.year}</Label>
                <Label className="shrink truncate" >{data?.comment}</Label>
                <div className="grow"></div>
                <Label className=" min-w-[300px] shrink-0" >{data?.invoiceDate}</Label>
            </div>
            <div className="flex gap-firstDegree shrink-0">
                <Label fontWeight="bold" message="consultants.expense.amountExclTaxs" />
                <Label >{data?.amountExcludingVat} €</Label>
            </div>

            <div className="flex gap-firstDegree items-center shrink-0">
                <Label fontWeight="bold" message="consultants.expense.amountTax" />
                <Popover open={open} onOpenChange={setOpen} >
                    <PopoverTrigger onMouseOver={(e) => setOpen(true)} onMouseOut={(e) => setOpen(false)} asChild className="flex gap-firstDegree items-center ">
                        <div className="relative">
                            <img src={Polygon1} className="absolute top-[-2px] left-[-6px] opacity-50" />

                            <Label  className="">{totalTax.toFixed(2)} €</Label>
                        </div>

                    </PopoverTrigger>
                    <PopoverContent className="flex flex-col gap-firstDegree w-auto">
                        {data?.vatList && data?.vatList.map((item, index) => (
                            <div className="flex gap-firstDegree" key={index}>
                                <Label size="body" fontWeight="bold">{item.vatRate} % :</Label><Label size="body"> {item.vatAmount} €</Label>
                            </div>
                        ))}
                    </PopoverContent>
                </Popover>
            </div>
            <ControlsDownload
                dataOwner={dataOwner}
                onClickNewTab={handleOpenInvoice}
                onClickDownload={handleGetInvoice}
                onClickDelete={() => deleteCompanyBenefit(data?.uuid)}
                disabledOpenNewTab={!data?.companyBenefitInvoiceFileUuid || errorFile}
                disabledDelete={errorDelete}
                hideDownload
                hideEdit={!editable || !hasPrivileges('ADMIN_COMPANY_BENEFIT')}
                titleForDelete={t(`consultants.companyBenefits.` + (BENEFITS_TYPE.find(benefit => benefit.enum === data?.benefitType)?.label || '')) + " " + data?.month + "/" + data?.year + " - " + data?.comment}
                onClickEdit={() => setIsEditModalOpen(true)}
            />
            {isEditModalOpen && (
                <EditAdminModal
                    data={data}
                    onClose={() => setIsEditModalOpen(false)}
                    type="companyBenefit"
                    form={
                        <FormNewCompanyBenefit data={data} forEdition onSubmit={handleSubmitUpdate} submitRef={submitRef} submissionSuccess={submissionSuccess} submissionError={submissionError} setSubmissionError={setSubmissionError} setSubmissionSuccess={setSubmissionSuccess}/>
                    }
                    onSubmitForm={() => submitRef.current && submitRef.current.click()}
                />
            )}
            {loadingFile ||loadingDelete && <span className="loader" />}

        </div>
    );
}