import {
    Sheet,
    SheetTrigger,
    SheetContent,
    SheetHeader,
    SheetSlot,
} from "@/components/templates/sheet";
import { useState, useEffect } from "react";
import { Button } from "@/components/molecules/buttons/button";
import Action from "@/components/molecules/buttons/Action";
import AddTask from "@mui/icons-material/AddTask";
import useSetSkillPanelData from "./useSetSkillPanelData";
import PrintSkillAdded from "./PrintSkillAdded";
import AddNewSkill from "./AddNewSkill/AddNewSkill";
import { Separator } from "@/components/atoms/divider/separatorShadcn";
import { Select, SelectItem, SelectTrigger, SelectValue, SelectContent } from "@/components/ui/select";
import { Label } from "@/components/atoms/texts/label";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
// import { useAllSkills } from "@/services/global-services/useAllSkills";
import { useTranslation } from "react-i18next";
import { useAllSkills } from "@/services/global-services/query/useAllSkills";
import { motion } from "framer-motion";
import { Input } from "@/components/ui/input";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import InViewComponent from "@/components/organisms/InViewComponent";

const skillsMapLang = {
    en: "labelEn",
    es: "labelEs",
    fr: "labelFr",
    it: "labelIt",
    nl: "labelNl",
};

export default function SetSkillsPanel({ ...props }) {
    const [isOpen, setIsOpen] = useState(false);
    const { skillsAdded, error } = useSetSkillPanelData();
    const [skills, setSkills] = useState([]);
    const [changeContent, setChangeContent] = useState(false);
    const [skillName, setSkillName] = useState("");
    const [numberOfConsultant, setNumberOfConsultant] = useState(0);
    const [addingNewSkill, setAddingNewSkill] = useState(false);

    const SortOrderEnum = {
        ASSIGNED_USERS_COUNT_ASC: "Nb d'assignement croissant",
        ASSIGNED_USERS_COUNT_DESC: "Nb d'assignement décroissant",
        LABEL_ASC: "Nom A->Z",
        LABEL_DESC: "Nom Z->A",
        SCORE_DESC: "Score décroissant"
    };
    const [searchString, setSearchString] = useState("");
    const [sortOrder, setSortOrder] = useState("SCORE_DESC"); //LASTNAME_ASC, LASTNAME_DESC, CREATED_ASC, CREATED_DESC
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [allSkills, setAllSkills] = useState([]);
    const [lastSlice, setLastSlice] = useState(1);

    const {
        loading,
        error: errorSkills,
        data: dataSkills,
        refetch,
    } = useAllSkills(searchString, lastSlice, sortOrder);
    const { i18n } = useTranslation("global");
    const [t] = useTranslation("global");

    const currentLanguageCode = i18n.language;
    const fieldLangToPrint = skillsMapLang[currentLanguageCode];
    const [isdeleting, setIsdeleting] = useState(false);
    const [selectedSkill, setSlectedSkill] = useState(null);

    //   console.log("Current lang code", fieldLangToPrint);
    useEffect(() => {
        setSkills(skillsAdded);
    }, [skillsAdded]);

    useEffect(() => {
        refetch();
        setAllSkills(dataSkills?.skills?.skills);
        setLastSlice(1);
    }, [isOpen]);

    useEffect(() => {
        isdeleting && refetch();
        setIsdeleting(false);
        //setAllSkills(dataSkills);
    }, [isdeleting]);

    const repeatedNames = Array.from(
        { length: numberOfConsultant },
        (_, index) => index
    );
    //   console.log("skills added", skillsAdded)
    //   console.log("skills ff", skills)

    // if (errorSkills) return <PrintErrorMessage />;
    // if (!dataSkills) return <PrintFetchingData />;
    useEffect(() => {
        console.log("use effect dataSkills lastSlice", lastSlice, dataSkills);
        // setAllSkills(dataSkills);
        if (!loading && dataSkills) {
            //     // Mettre à jour la copie des dépenses avec les nouvelles données
            if (lastSlice == 1) {
                console.log("use effect dataSkills true", allSkills, dataSkills);
                setAllSkills(() => [
                    ...dataSkills?.skills?.skills
                ]);
            } else {
                console.log("use effect dataSkills false", allSkills, dataSkills);

                setAllSkills((prevSkills) => [
                    ...prevSkills,
                    ...dataSkills?.skills?.skills
                ]);
            }
        }
        // Vous pouvez effectuer des actions supplémentaires ici si nécessaire
    }, [dataSkills]);

    useEffect(() => {
        refetchFromScratch();
    }, [searchString]);

    useEffect(() => {
        refetch();
        // Vous pouvez effectuer des actions supplémentaires ici si nécessaire
    }, [changeContent, addingNewSkill]);

    const handleOpenChange = (e) => {
        setIsOpen(e);
        setChangeContent(false);
        setAddingNewSkill(false);
    }


    const refetchFromScratch = (n = 1) => {
        setLastSlice(n);
        setAllSkills([]);
        refetch(n);
        console.log(n)
    };
    const fetchNewSlice = () => {
        console.log("fetch new slice", (!loading && allSkills?.length > 0 && dataSkills?.skills?.hasNext), lastSlice);
        if (!loading && allSkills?.length > 0 && dataSkills?.skills?.hasNext) {
            setLastSlice((prevSlice) => prevSlice + 1);
        }
    };


    return (
        <Sheet open={isOpen} onOpenChange={handleOpenChange}>
            <SheetTrigger asChild>
                <Action
                    icon={<AddTask />}
                    text="consultants.dashboard.actions.skills"
                    initialVariant={isOpen ? "active" : "default"}
                    {...props}
                />
            </SheetTrigger>
            <SheetContent>
                <SheetHeader title={"consultants.statement.setSkillsPanel.addSkill"} />
                <SheetSlot>
                    {addingNewSkill && (
                        <AddNewSkill
                            setSkills={setSkills}
                            setAddingSkill={setAddingNewSkill}
                        />
                    )}

                    {!addingNewSkill && (
                        <div>
                            {!changeContent && !addingNewSkill ? (
                                <div className=" flex flex-col gap-secondDegree py-paddingTopAndBottom px-paddingLeftAndRight w-full items-center">
                                    <Button
                                        showLeftIcon={true}
                                        leftIcon={<AddIcon />}
                                        showText={true}
                                        text="global.actions.add"
                                        sizePadding="default" // Utilisez "small" pour un padding plus petit
                                        style="fill"
                                        className=" "
                                        //type="submit"
                                        onClick={() => setAddingNewSkill(true)} // Options: "fill", "outline", "ghost", "dressedGhost"
                                    />

                                    {/* <div className=" flex flex-col gap-secondDegree py-paddingTopAndBottom px-paddingLeftAndRight w-full items-start"> */}
                                        <Separator />
                                        <Label
                                            message="consultants.statement.setSkillsPanel.activeSkills"
                                            fontWeight="bold"
                                            className="justify-center flex"
                                        />
                                        {<div className=" w-full flex justify-end gap-secondDegree ">
                                            <Select value={sortOrder} onValueChange={(value) => setSortOrder(value)}>
                                                <SelectTrigger className="flex-none w-fit  h-12 py-paddingTopAndBottom bg-white gap-2 text-gray-400 ">
                                                    <SelectValue placeholder={t("global.actions.select")} />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectItem value="ASSIGNED_USERS_COUNT_ASC"><Label className="text-night">{SortOrderEnum.ASSIGNED_USERS_COUNT_ASC}</Label></SelectItem>
                                                    <SelectItem value="ASSIGNED_USERS_COUNT_DESC"><Label className="text-night">{SortOrderEnum.ASSIGNED_USERS_COUNT_DESC}</Label></SelectItem>
                                                    <SelectItem value="LABEL_ASC"><Label className="text-night">{SortOrderEnum.LABEL_ASC}</Label></SelectItem>
                                                    <SelectItem value="LABEL_DESC"><Label className="text-night">{SortOrderEnum.LABEL_DESC}</Label></SelectItem>
                                                    <SelectItem value="SCORE_DESC"><Label className="text-night">{SortOrderEnum.SCORE_DESC}</Label></SelectItem>
                                                </SelectContent>
                                            </Select>

                                            {/* {searchString} */}
                                            <div
                                                className="relative w-full  flex grow "
                                                onFocus={() => setIsInputFocused(true)}
                                                onBlur={() => setIsInputFocused(false)}
                                            >
                                                {searchString?.length > 0 && <ClearRoundedIcon onMouseDown={() => { setSearchString(""); console.log("click on delete") }} className={`absolute inset-y-0 my-auto left-0 hover:cursor-pointer mx-2  ${isInputFocused ? 'text-primary' : 'text-gray-400'} `} />}
                                                <Input
                                                    type="string"
                                                    placeholder={t("consultants.statement.prospectConsultantPanel.formPlaceHolder.skill")}
                                                    className={`w-full h-12 py-paddingTopAndBottom ${searchString.length > 0 ? "pl-8" : ""}`}
                                                    onChange={(e) => setSearchString(e?.target?.value)}
                                                    value={searchString}
                                                />
                                                <div className="absolute inset-y-0 right-0 my-auto mr-secondDegree flex gap-secondDegree items-center ">
                                                    {(searchString.length <= 1 && searchString.length > 0) && <Label color={`error`}>Min 2 car</Label>}
                                                    <SearchRoundedIcon
                                                        onClick={() => refetch()}
                                                        className={`hover:cursor-pointer ${isInputFocused ? 'text-primary' : 'text-gray-400'}`}
                                                    />
                                                </div>
                                            </div>
                                        </div>}
                                        {allSkills &&
                                            allSkills?.map((skill, index) => {
                                                return (
                                                    <PrintSkillAdded
                                                        skill={skill}
                                                        key={skill.uuid}
                                                        setIsdeleting={setIsdeleting}
                                                        setChangeContent={setChangeContent}
                                                        skills={allSkills}
                                                        setNumberOfConsultant={setNumberOfConsultant}
                                                        setSkillName={setSkillName}
                                                        labelLangSkill={fieldLangToPrint}
                                                        setSlectedSkill={setSlectedSkill}
                                                    />
                                                );
                                            })}
                                    {/* </div> */}
                                    {loading && <span className="loader" />}
                                    <InViewComponent inView={fetchNewSlice} />

                                </div>
                            ) : (
                                <div className=" flex flex-col gap-secondDegree p-secondDegree">
                                    <div className="flex justify-start">
                                        <Button
                                            showLeftIcon={true}
                                            leftIcon={<ArrowBackIcon fontSize="small" />}
                                            showText={false}
                                            // text="global.actions.back"
                                            sizePadding="default" // Utilisez "small" pour un padding plus petit
                                            style="ghost"
                                            className="font-bold  "
                                            onClick={() => { setChangeContent(false); setAddingNewSkill(false) }} // Options: "fill", "outline", "ghost", "dressedGhost"
                                        />
                                    </div>

                                    <div className=" flex flex-col gap-firstDegree">
                                        <div className="flex flex-row gap-betweenText">
                                            <Label
                                                message="consultants.statement.prospectConsultantPanel.formPlaceHolder.skill"
                                                fontWeight="bold"
                                            />

                                            <Label>{": " + selectedSkill[fieldLangToPrint]}</Label>
                                        </div>

                                        {/* {to change after} */}

                                        {
                                            selectedSkill?.skillAssignedUsers?.map(
                                                (consultant, index) => (
                                                    <Label key={index}>
                                                        {consultant.firstname + " " + consultant.lastname}
                                                    </Label>
                                                )
                                            )}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </SheetSlot>
                {/* {!changeContent &&
          !addingNewSkill &&(
            <SheetFooter
              //warning={"header.message"}
              //consent={"header.message"}
              //closeButton={true}
              buttonOutlineText={changeContent && translationsPaths.backAction}

              // onClickOutline={handleChangeContent}
              // onClickDressed={handleCalledFunction}
            ></SheetFooter>
          )} */}
            </SheetContent>
        </Sheet>
    );
}
