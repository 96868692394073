import { Label } from "@/components/atoms/texts/label";
import ReadingStatus from "./readingStatus";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/molecules/popover";
import { Button } from "@/components/molecules/buttons/button";
import { useEffect, useState } from "react";
import Polygon1 from "@/assets/Polygon1.svg"
import ControlsDownload from "@/components/molecules/controlsDownload";
import getCurrencySymbolFromCode from "@/utils/getCurrencySymbolFromCode";
import { Toaster } from "@/components/organisms/feedback/toaster";
import { useDeleteExpenseReport } from "@/services/consultant-services/mutation/useDeleteExpenseReport";
import PrintErrorMessage from "@/components/organisms/feedback/PrintErrorMessage";
import { useToast } from "@/components/organisms/feedback/use-toast";
import { useGetCompanyBenefitInvoice } from "@/services/consultant-services/api-rest/useGetCompanyBenefitInvoice";
import { useGetExpenseReportProof_AsUser } from "@/services/consultant-services/api-rest/useGetExpenseReportProof_AsUser";
import { countries, getCountryData, getCountryDataList } from "countries-list";
import { useGetExpenseReportProof_AsAdmin } from "@/services/admin-services/api-rest/useGetExpenseReportProof_AsAdmin";
import { useGetSkillLabel } from "@/utils/getSkillLabel";
import { useAuth } from "@/context/AuthContext";
import { Dialog, DialogTrigger, DialogContent, DialogTitle, DialogDescription, DialogClose } from "@/components/ui/dialog";
import EditAdminModal from "@/components/macro-organisms/EditAdminModal";
import FormNewExpense from "./formNewExpense";
import ExpensePanelForReSubmit from "./expensePanelForReSubmit";


export default function Expense({ data, dataOwner = false, refetch, editable = false, ...props }) {

    const [open, setOpen] = useState();
    const totalTax = data?.vatList ? data?.vatList.reduce((acc, vat) => acc + parseFloat(vat.vatAmount), 0) : 0;
    const symbol = getCurrencySymbolFromCode(data?.currency)
    const [openProof, setOpenProof] = useState(false);
    const [downloadProof, setDownloadProof] = useState(false);
    const { deleteExpenseReport, loading: loadingDelete, error: errorDelete, data: dataDelete } = useDeleteExpenseReport();
    const { getExpenseProof, loading: loadingExpenseProof, error: errorExpenseProof, data: dataExpenseProof } = dataOwner ? useGetExpenseReportProof_AsUser() : useGetExpenseReportProof_AsAdmin();

    const { toast } = useToast();
    let labelLanguage = useGetSkillLabel();
    const [openPanelForResubmit, setOpenPanelForResubmit] = useState(false);
    // const { hasPrivileges } = useAuth();
    // const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const handleGetExpenseProof = () => {
        console.log("handleGetExpenseProof", data?.expenseReportFileUuid)
        if (data?.expenseReportFileUuid) {
            getExpenseProof(data?.expenseReportFileUuid);
        }
    };

    const handleOpenInvoice = () => {
        if (!dataExpenseProof) {
            handleGetExpenseProof();
        }
        dataExpenseProof && window.open(dataExpenseProof?.url, "_blank")// Ouvrir l'URL dans un nouvel onglet
    }
    // const handleOnDoubleClick = () => {
    //     console.log("handleOnDoubleClick", editable);
    //     if (editable) {
    //         setOpenPanelForResubmit(true);
    //         onDoubleClick();
    //     } else {
    //         onDoubleClick();
    //     }
    // }
    const deleteExpense = (uuidExpense) => {
        console.log("deleteExpense", uuidExpense);
        deleteExpenseReport(uuidExpense);

    }
    useEffect(() => {
        dataExpenseProof && window.open(dataExpenseProof?.url, "_blank")// Ouvrir l'URL dans un nouvel onglet
    }, [dataExpenseProof]);


    useEffect(() => {
        if (dataDelete?.deleteExpenseReport) {
            toast({
                variant: "success",
                title: "success.backendOperation.delete",
                duration: 8000,
                description: "La note de frais " + data?.invoiceIssuer + " " + data?.comment + " a été supprimée"
            })
            refetch(1)
        } else if (dataDelete?.deleteExpenseReport == false && errorDelete) {
            toast({
                variant: "error",
                title: "error.requestFailed",
                duration: 8000,
                description: errorDelete?.message
            })
        }
    }, [dataDelete])

    useEffect(() => {
        const displayToast = () => {
            toast({
                variant: "error",
                title: "error.requestFailed",
                duration: 8000,
                description: errorDelete?.message
            })
        }
        errorDelete && !loadingDelete && displayToast();
    }, [errorDelete]);


    return (
        <div className={`bg-white w-full shadow-md px-paddingLeftAndRight py-paddingTopAndBottom rounded-sm justify-start items-center inline-flex gap-secondDegree ${((data?.status == "REJECTED" && dataOwner) || !dataOwner) && "hover:bg-medium hover:cursor-pointer"}`} {...props}>
            {/* <Toaster /> */}
            {/* {editable && <ExpensePanelForReSubmit openExpensePanelResubmit={openPanelForResubmit} data={data} refetchFromScratch={refetch} />} */}
            <ReadingStatus type={data?.status} adminComment={data?.adminComment} />

            <div className="max-w-[15%] overflow-hidden flex gap-firstDegree">

            </div>

            {/* <Label fontWeight="">n°{data?.category.accountingAccountNumber}</Label> */}
            {/* //TODO passer tout les labelFr de l'app en utilisant la fonction dynamique */}
            <div className="max-w-[50%] overflow-hidden flex gap-firstDegree flex-col 2xl:flex-row items-start">
                <Label className="visible text-nowrap" fontWeight="bold">{data?.category[labelLanguage]}</Label>

                <Label className=" visible text-nowrap">{data?.invoiceIssuer} - {countries[data?.countryCode.toUpperCase()].name}</Label>
                <Label className="truncate italic">{data?.comment} </Label>

                {(data?.impactsOverflow == false && data?.status !== 'SUBMITTED') && <div className="border border-grey px-1 rounded-sm text-greyStrong italic flex items-center"><Label message="consultants.expense.impactsOverflow.falseDisplay" /></div>}
            </div>

            <div className="grow"></div>
            <div className="max-w-[45%] min-w-[250px] justify-end lg:min-w-[400px] overflow-hidden flex gap-firstDegree items-center">


                <Label fontWeight="">{data?.invoiceDate}</Label>
                {data?.countryCode == "lu" ?
                    <div className="flex gap-firstDegree">
                        <Label className="hidden lg:flex" fontWeight="bold" message="consultants.expense.amountExclTaxs" />
                        <Label fontWeight="">{data?.amountExcludingVat} {symbol}</Label>
                    </div> :
                    <div className="flex gap-firstDegree">
                        <Label className="hidden lg:flex" fontWeight="bold" message="consultants.expense.amountInclTaxs" />
                        <Label fontWeight="">{data?.totalAmount} {symbol}</Label>
                    </div>
                }



                {data?.countryCode == "lu" && <div className="flex gap-firstDegree items-center">
                    <Label className="hidden lg:flex" fontWeight="bold" message="consultants.expense.amountTax" />
                    <Popover open={open} onOpenChange={setOpen} >
                        <PopoverTrigger onMouseOver={(e) => setOpen(true)} onMouseOut={(e) => setOpen(false)} asChild className="flex gap-firstDegree items-center ">
                            <div className="relative">
                                <img src={Polygon1} className="absolute top-[-2px] left-[-6px] opacity-50" />

                                <Label fontWeight="" className="">{totalTax.toFixed(2)} {symbol}</Label>
                            </div>

                        </PopoverTrigger>
                        <PopoverContent className="flex flex-col gap-firstDegree w-auto">

                            {data?.vatList && data?.vatList.map((vat, index) => (
                                <div className="flex gap-firstDegree" key={index}>
                                    <Label size="body" fontWeight="bold">{vat.vatRate}% :</Label>
                                    <Label size="body">{vat?.vatAmount.toFixed(2)} {symbol}</Label>
                                </div>
                            ))}
                        </PopoverContent>
                    </Popover>
                </div>}

                {/* <Label fontWeight="">{data?.sizeFile}</Label> */}
                <div>
                    <ControlsDownload
                        dataOwner={dataOwner}

                        onClickNewTab={() => handleOpenInvoice()}
                        disabledOpenNewTab={!data?.expenseReportFileUuid || errorExpenseProof}

                        hideDownload
                        hideDelete={data?.status == 'APPROVED' || data?.status == 'REJECTED'}
                        // hideEdit={!editable || !hasPrivileges('ADMIN_EXPENSE')}
                        hideEdit
                        // onClickEdit={() => setIsEditModalOpen(true)}
                        onClickDelete={() => deleteExpense(data?.uuid)}
                        disabledDelete={errorDelete}
                        titleForDelete={data?.invoiceIssuer + " - " + data?.comment}
                    />
                    {/* {isEditModalOpen && (
                        <EditAdminModal
                            data={data}
                            onClose={() => setIsEditModalOpen(false)}
                            type="expense"
                            form={
                                <FormNewExpense
                                    // onSubmit={handleSubmit}
                                    // submitRef={submitRef}
                                    data={data}
                                // isForApprovalUseCase
                                // refetchFromScratch={refetchFromScratch}
                                />
                            }
                        />
                    )} */}
                </div>

                {/* {editable && <Label>Edit</Label>} */}
                {loadingDelete || loadingExpenseProof && <span className="loader" />}
            </div>

        </div>
    )
}