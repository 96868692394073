

import React, { useRef, useState, useEffect } from 'react';
import { Sheet, SheetTrigger, SheetContent, SheetHeader, SheetSlot, SheetFooter, SheetClose } from "@/components/templates/sheet";
import { Button } from '@/components/molecules/buttons/button';
import { AddRounded, DirectionsCar } from '@mui/icons-material';
import { Label } from '@/components/atoms/texts/label';
import FileUploadZone from './FilesUploadZone';
import FormNewCompanyBenefit from '@/components/macro-organisms/panels/panels-admin/newCompanyBenefit/FormNewCompanyBenefit';
import Action from '@/components/molecules/buttons/Action';
import { Separator } from '@/components/atoms/divider/separatorShadcn';
import CreateAndUpdateCV from '../prospectConsultant/CreateAndUpdateCV';
import AddManuallyProspect from './AddManuallyProspect';

import ShowAllCvProcessing from './ShowAllCvProcessing';
export default function AddProspectsPanel({setIsDoingAction,isDoingAction,refetch,setShouldPrintNumberProspects, ...props}) {
    // const submitRef = useRef(null);
    const date = new Date();
    const [actualDate, setActualDate] = useState(null);
    // Soustrait 2 minutes à la date actuelle
    //  date.setMinutes(date.getMinutes() - 3);

    // Convertit la date au format ISO avec un décalage de temps explicite
    const createdAfter = date.toISOString().replace(/\.\d{3}Z$/, "+00:00");
    const [open, setOpen] = useState(false);
    const [addManually, setAddManually] = useState(false);
    const [addFromFiles, setAddFromFiles] = useState(false);
    const [hideFileComponent, setHideFileComponent] = useState(false);
    const resetAllFlags = (flag) => {
        setIsDoingAction(true);
        setOpen(flag);
        setShouldPrintNumberProspects(flag)
        setAddManually(false);
        setHideFileComponent(false);
        setAddFromFiles(false);
        setActualDate(createdAfter);
        // console.log("open and close")
    };

    useEffect(() => {
        // console.log("hideFileComponent", hideFileComponent);
    }, [hideFileComponent]);

    return (
        <Sheet open={open} onOpenChange={resetAllFlags} >
            <SheetTrigger className={`w-full`} {...props}>
                <Button
                    leftIcon={<AddRounded />}
                    showLeftIcon
                    text="prospects.newProspect"
                    className="float-right"
                />
            </SheetTrigger>
            <SheetContent>
                <SheetHeader title={"prospects.newProspect"} />
                <SheetSlot className=" flex  relative">
                    {/* <FormNewCompanyBenefit onSubmit={handleSubmit} submitRef={submitRef} global={global} /> */}
                    {!addManually && !addFromFiles && (
                        <div className="flex flex-col gap-secondDegree p-secondDegree">
                            <Button
                                showLeftIcon={true}
                                showRightIcon={true}
                                text="prospects.createNewProspect"
                                sizePadding="default" // Utilisez "small" pour un padding plus petit
                                style="fill"
                                onClick={() => setAddFromFiles(true)} // Options: "fill", "outline", "ghost", "dressedGhost"
                            />
                            <div className="flex flex-row gap-firstDegree items-center">
                                <Separator className="w-[46%]" />
                                <Label message="global.information.or" />
                                <Separator className="w-[46%]" />
                            </div>
                            <Button
                                showLeftIcon={true}
                                showRightIcon={true}
                                text="prospects.addManually"
                                sizePadding="default" // Utilisez "small" pour un padding plus petit
                                style="outline"
                                onClick={() => setAddManually(true)} // Options: "fill", "outline", "ghost", "dressedGhost"
                            />
                        </div>
                    )}

                    {addManually && (
                        <AddManuallyProspect
                            setIsOpen={resetAllFlags}
                            setIsDoingAction={setIsDoingAction}
                            refetch={refetch}
                        />
                    )}
                    {addFromFiles && (
                        <FileUploadZone
                            hideFileComponent={hideFileComponent}
                            setHideFileComponent={setHideFileComponent}
                            setIsDoingAction={setIsDoingAction}
                        />
                    )}
                    {hideFileComponent && (
                        <ShowAllCvProcessing
                            setIsDoingAction={setIsDoingAction}
                            actualDate={actualDate}
                        />
                    )}
                </SheetSlot>

            </SheetContent>
        </Sheet>
    );
}



