import { useAuth } from '@/context/AuthContext';
import { useState } from 'react';
import errorToText from '@/utils/errorToText';

const useCurrentUser = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [currentUserInformation, setCurrentUserInformation] = useState(null);

    const { user: authUser } = useAuth();
    // console.log("env back end ", window.ENV.BACKEND_URL, window)

    const getCurrentUser = async (token) => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(window.ENV.BACKEND_URL + '/api/graphql', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    query: `
                        query MyQuery {
                            currentUser {
                                uuid
                                role {
                                    name
                                    privileges {
                                        name
                                    }
                                }
                                company {
                                    uuid
                                    name
                                  }
                                language
                                firstname
                                lastname
                            }
                        }
                    `
                })
            });

            if (!response.ok) {
                const errMessage = errorToText(response);
                setLoading(false);
                throw new Error(errMessage);
            }

            const data = await response.json();
            if (data.errors) {
                const errMessage = data.errors[0].message;
                setLoading(false);
                throw new Error(data);
            }

            setCurrentUser(data.data.currentUser);
            setLoading(false);
            return { data: data.data.currentUser, error: null };
        } catch (err) {
            setError(err);
            setLoading(false);
            return { data: null, error: err };
        }
    };

    const getCurrentUserInformation = async () => {
        setLoading(true);
        setError(null);

        if (!authUser.strongToken) {
            return { data: null, error: 'No strong token available' };
        }
        // console.log("getCurrentUserInformation",authUser.strongToken );
        try {
            const response = await fetch(window.ENV.BACKEND_URL + '/api/graphql', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authUser.strongToken}`
                },
                body: JSON.stringify({
                    query: `
                        query MyQuery {
                            currentUser {
                                email
                                personalEmail
                                addressLine1
                                phoneNumber
                                addressLine2
                                city
                                countryCode
                                entryDate
                                iban
                                bicCode
                                cooptedBy {
                                  cooptant {
                                    firstname
                                    lastname
                                    uuid
                                  }
                                }
                                cooptantFor {
                                  coopted {
                                    firstname
                                    lastname
                                    uuid
                                  }
                                  monthsDuration
                                  percentage
                                  startDate
                                }
                                consultantProfile {
                                  name
                                  uuid
                                  cooptationType
                                }
                                latestStatementMonth {
                                    commission
                                }
                                retributionModel {
                                  upToFiveConsultants
                                  upToTenConsultants
                                  moreThanTenConsultants
                                }
                                zipCode
                                lastname
                                firstname
                                uuid
                                
                              }
                        }
                    `
                })
            });
            // console.log("getCurrentUserInformation response",response )

            if (!response.ok) {
                const errMessage = errorToText(response);
                setLoading(false);
                throw new Error(errMessage);
            }

            const data = await response.json();
            if (!data.data && data.errors) {
                const errMessage = data.errors[0].message;
                setLoading(false);
                throw new Error(data);
            }

            setCurrentUserInformation(data.data.currentUser);
            setLoading(false);
            return { data: data.data.currentUser, error: null };
        } catch (err) {
            setError(err);
            setLoading(false);
            return { data: null, error: err };
        }
    };

    const refetchCurrentUser = async () => {
        return await getCurrentUserInformation();
    };

    return { getCurrentUser, currentUser, currentUserInformation, getCurrentUserInformation, refetchCurrentUser, loading, error };
};

export default useCurrentUser;
