import { gql } from "@apollo/client";

import { useAuth } from "@/context/AuthContext";
import { useQuery } from "@apollo/client";
export const GET_ALL_PROSPECTS = gql`
  query GetAllProspects($companyUuid: ID!, $sliceNumber: Int!, $searchString: String, $sortOrder: ProspectsSortOrder) {
    allProspects(
      prospectsRequest: { companyUuid: $companyUuid, sliceNumber: $sliceNumber , searchString: $searchString, sortOrder: $sortOrder}
    ) {
      hasNext
      prospects {
        firstname
        firstnameCompleted
        gender
        personalEmail
        birthdate
        uuid
        completionPercentage
        profileDensityScore
        genderCompleted
        lastnameCompleted
        lastname
        createdAt
        cooptedBy {
          uuid
          firstname
          lastname
          trigram
        }
      }
    }
  }
`;


// Définir le hook// Définir le hook
export const useAllProspects = ( sliceNumber, searchString, sortOrder) => {
  const { user } = useAuth();
 const companyUuid = user.company.uuid;
  const { loading, error, data, fetchMore, refetch } = useQuery(
    GET_ALL_PROSPECTS,
    {
      variables: { companyUuid, sliceNumber, searchString, sortOrder },
      fetchPolicy: "network-only",
      context: {
        headers: {
          Authorization: `Bearer ${user.strongToken}`, // Ajustez selon la méthode d'authentification
        },
      },
    }
  );

  // Fonction pour charger plus de prospects si nécessaire
  const loadMoreProspects = () => {
    if (data?.allProspects?.hasNext) {
      fetchMore({
        variables: {
          sliceNumber: sliceNumber + 1, // Incrémenter pour charger la tranche suivante
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            ...prev,
            allProspects: {
              ...fetchMoreResult.allProspects,
              prospects: [
                ...prev.allProspects.prospects,
                ...fetchMoreResult.allProspects.prospects,
              ],
            },
          };
        },
      });
    }
  };

  return { loading, error, data, loadMoreProspects, refetch };
};